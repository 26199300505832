import React, { Component } from 'react'
import rehypeReact from "rehype-react"
import PropTypes from 'prop-types'
import NewsletterCtaRow from 'components/newsletter-cta-row'
import ExplanationCard from 'components/explanation-card'
import { Link, graphql } from 'gatsby'
import marked from 'marked'

import { highlight_1, highlight_6, highlight_7 } from 'images/blog'
import Helmet from 'react-helmet'

import Layout from '../layouts'
import config from '../config'
const { appUrl, signupRoute } = config

import { articleImage } from 'images/hulp'

import BackgroundImage from 'gatsby-background-image'
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import { FaTwitter } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'
import { FaGooglePlus } from 'react-icons/fa'
import { FaEnvelope } from 'react-icons/fa'
import { FaPinterest } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'


class KennisbankArticle extends Component {
  render() {
    const { edges } = this.props.data.allContentfulArticle
    const allContent = edges[0].node.content.childMarkdownRemark.htmlAst;

    const renderAst = new rehypeReact({
      createElement: React.createElement,
      components: { "explanation-card": ExplanationCard },
    }).Compiler



    const jsonLd = this.props.data.allContentfulArticle.edges[0].node.jsonLd

    let jsonLDPresent = false

    if (jsonLd) {
      jsonLDPresent = true
    }

    const metaImage = this.props.data.allContentfulArticle.edges[0].node.metaImage

    let metaImagePresent = false

    if (metaImage) {
       metaImagePresent = true
    }


    const image = getImage(this.props.data.allContentfulArticle.edges[0].node.heroImage)

    {/*
      const backgroundFluidImageStack = [
      this.props.data.allContentfulArticle.edges[0].node.heroImage.fluid,
      `linear-gradient(-180deg, rgba(0,0,0,0.00) 50%, rgba(0,0,0, .7) 100%)`,
    ].reverse()
    */}

    return (
      <Layout location={this.props.location}>
        <main className="kennisbank article animated">
          <header>
            <Helmet>
              <title>{edges[0].node.metaTitle}</title>
              <meta name="description" content={edges[0].node.metaDescription}/>
              <meta itemprop="name" content={edges[0].node.metaTitle} />
              <meta itemprop="description" content={edges[0].node.metaDescription} />

              { metaImagePresent ? (
                <meta
                  itemprop="image"
                  content={ "https:" + this.props.data.allContentfulArticle.edges[0].node.metaImage.file.url } /> ):
                  <meta
                    property="og:image"
                    content="https://employes.nl/static/meta-img.png"
                  />
              }

              {/* Windows tags */}
              <meta name="msapplication-TileColor" content="#ffffff" />
              <meta name="msapplication-TileImage" content="/static/favicon/mstile-144x144.png" />

              {/* OpenGraph tags */}
              <meta property="og:url" content={"https://employes.nl/"+ edges[0].node.slug + "/"} />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={edges[0].node.metaTitle} />
              <meta property="og:description" content={edges[0].node.metaDescription} />

              { metaImagePresent ? (
                <meta property="og:image" content={ "https:" + this.props.data.allContentfulArticle.edges[0].node.metaImage.file.url }/> ):
                <meta
                  property="og:image"
                  content="https://employes.nl/static/meta-img.png"
                />
              }

              {/* Twitter Card tags */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={edges[0].node.metaTitle} />
              <meta name="twitter:description" content={edges[0].node.metaDescription} />
              {/*<meta name="twitter:image" content={ "https://" + this.props.data.allContentfulArticle.edges[0].node.heroImage.fluid.src } />*/}

              {jsonLDPresent ? (
                <script type="application/ld+json">{`
                  {
                    "@context": "http://schema.org",
                    "@type": "${this.props.data.allContentfulArticle.edges[0].node.jsonLd._type}",
                    "mainEntity" : {
                      "@type" : "${this.props.data.allContentfulArticle.edges[0].node.jsonLd.mainEntity._type}",
                      "name" : "${this.props.data.allContentfulArticle.edges[0].node.jsonLd.mainEntity.name}",
                      "answerCount": "1",
                      "acceptedAnswer" : {
                        "@type" : "${this.props.data.allContentfulArticle.edges[0].node.jsonLd.mainEntity.acceptedAnswer._type}",
                        "text" : "${this.props.data.allContentfulArticle.edges[0].node.jsonLd.mainEntity.acceptedAnswer.text}"
                      },
                      "suggestedAnswer" : {
                        "@type" : "${this.props.data.allContentfulArticle.edges[0].node.jsonLd.mainEntity.suggestedAnswer._type}",
                        "text" : "${this.props.data.allContentfulArticle.edges[0].node.jsonLd.mainEntity.suggestedAnswer.text}"
                      }
                    }
                  }
                `}</script>
              ) : (
                ''
              )}
            </Helmet>


            <div style={{display: "grid"}} className="container-md heading-photo">


                  <GatsbyImage
                    style={{
                      gridArea: "1/1",
                      maxWidth: 1184,
                      maxHeight: 500,
                      borderRadius: 8
                    }}
                    layout="fixed"
                    alt=""
                    image={image}
                    formats={["auto", "webp", "avif"]}
                    loading="eager"
                  />


                  <div
                    style={{
                      gridArea: "1/1",
                      position: "relative",
                      display: "grid",
                      alignItems: "end",
                      borderRadius: 8,
                      backgroundImage: "linear-gradient(-180deg, rgba(0,0,0,0.00) 50%, rgba(0,0,0, .7) 100%)"
                    }}>


                      <div className="heading-photo-text">

                        <Link to="/blog/" className="link mulberry">
                          Blog
                        </Link>

                        <span className="arrow right">→</span>

                        <Link to={"/blog/"+ edges[0].node.category.slug + "/"} className="link mulberry">
                           {edges[0].node.category.title}
                        </Link>

                        <h1 className="margin-m-bottom">
                          <div dangerouslySetInnerHTML={{ __html: edges[0].node.title }}></div>
                        </h1>

                   

                      </div>

                    </div>


                </div>

            {/*<GatsbyImage
              tag="div"
              className="container-md heading-photo"
              image={image}
            />
              <div className="grid">

                <div className="col-12">
                  <Link to="/blog/" className="link mulberry">
                    Blog
                  </Link>

                  <span className="arrow right">→</span>

                  <Link to={"/blog/"+ edges[0].node.category.slug } className="link mulberry">
                   {edges[0].node.category.title}
                  </Link>

                  <h1 className="no-margin"><div dangerouslySetInnerHTML={{ __html: edges[0].node.title }}></div></h1>

                  {(edges[0].node.category.slug == "nieuws" ? <p>{edges[0].node.published}</p> : '')}

                </div>
              </div>
*/}

          </header>
          <section className="articles padding-xl padding-xxl-bottom">
            <div className="backdrop"></div>
            <div className="container-md flex">
              <div className="alinea">
              {/* {(edges[0].node.category.slug == "nieuws" ? <p>{edges[0].node.published}</p> : '')} */}
                <div>{renderAst(allContent)}</div>
              </div>
              <div className="cta-card right">
                <StaticImage src="../images/hulp/articleImage@2x.webp" alt="Employes gratis proberen" placeholder="blurred" loading="lazy" />
                <p className="eyebrow">Probeer nu gratis</p>
                <h5 className="margin-s-bottom">
                  Ervaar het gemak en probeer Employes gratis uit.
                </h5>
                <a href={appUrl + signupRoute} className="btn primary">
                  Probeer nu
                </a>

              </div>
            </div>
          </section>

        <div className="padding-xl-bottom">
          <NewsletterCtaRow />
          </div>

        <section className="related padding-xl-bottom">
          <div className="container-md relative">
            <div className="bg"></div>
            <h4>Populaire artikelen</h4>
            <div className="grid yg">
              <div className="col-4">
                  <Link to="/blog/wat-is-salarisadministratie/" className="articles-card">
                  <StaticImage className="small" src="../images/blog/kennisbank_highlighted_1.jpg" alt="Wat is salarisadministratie?" placeholder="blurred" loading="lazy" />
                  <div className="articles-card-content">
                    <p>Wat is salarisadministratie?</p>
                  </div>
                </Link>
              </div>

              <div className="col-4">
                <Link to="/blog/loonadministratie-zelf-doen/" className="articles-card">
                  <StaticImage className="small" src="../images/blog/kennisbank_highlighted_6.jpg" alt="Loonadministratie zel doen" placeholder="blurred" loading="lazy" />
                  <div className="articles-card-content">
                    <p>Loonadministratie zelf doen of uitbesteden?</p>
                  </div>
                </Link>
              </div>

              <div className="col-4">
                <Link to="/blog/overstappen-naar-employes/" className="articles-card">
                  <StaticImage className="small" src="../images/blog/kennisbank_highlighted_7.jpg" alt="Loonadministratie zel doen" placeholder="blurred" loading="lazy" />
                  <div className="articles-card-content">
                    <p>Hoe werkt overstappen naar Employes?</p>
                  </div>
                </Link>
              </div>
            </div>

            <div className="link-container">
              {/*<Link to={`/hulp/kennisbank/${edges[0].node.category.slug}/`} className="link blue">{ edges[0].node.category.title }</Link>*/}
              <Link to="/blog/" className="link blue">Kennisbank</Link>
            </div>

          </div>
        </section>

        </main>
      </Layout>
    )
  }
}

export default KennisbankArticle

export const articleQuery = graphql`
  query rightArticle($slug: String!) {
    allContentfulArticle(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          title
          metaTitle
          metaDescription
          slug
          published(formatString:"d MMMM YYYY",locale:"nl")
          heroImage {
            file{
              url
            }
            gatsbyImageData(
              width: 1184
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          metaImage {
            file{
              url
            }
          }
          content {
            childMarkdownRemark {
              htmlAst
            }
          }
          jsonLd {
            _type
            mainEntity {
              _type
              name
              _type
              suggestedAnswer {
                text
                _type
              }
              acceptedAnswer {
                text
                _type
              }
            }
          }
          category {
            id
            slug
            title
          }
        }
      }
    }
  }
`
