import { window } from 'browser-monads'
import React, { Component } from 'react'
import config from '../config'
const { appUrl, signupRoute } = config

class NewsletterCtaRow extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            submittedForm: false,
        }
    }

    submitFormHandler = (event) => {
        event.preventDefault()

        this.setState({
            submittedForm: true,
        })
    }

    render() {
        const { text, subtext, eyebrow } = this.props

        return (
            <section className="cta">
                <div className="cta-row padding-xl light">
                    <div className="container-md">
                        <div className="grid center text-center">
                            <div className="col-9">
                                <h6 className="eyebrow">Blijf up to date!</h6>
                                <h2>
                                    {text
                                        ? text
                                        : 'Schrijf je in voor onze nieuwsbrief'}
                                </h2>
                                <p className="cta-text">
                                    {subtext
                                        ? subtext
                                        : 'Schrijf je in en krijg het laatste HR- & salarisnieuws als eerste in je inbox.'}
                                </p>
                            </div>

                            <div className="col-6 margin-l-top sign-up-container">
                                <div className="sign-up">
                                    {this.state.submittedForm ? (
                                        <div>
                                            <h5>Bedankt voor je aanmelding.</h5>
                                            <p>
                                                Je ontvangt ter bevestiging een
                                                email.
                                            </p>
                                        </div>
                                    ) : (

                                      <form name="Blog | Aanmelding nieuwsbrief op de blog" className="sign-up" method="POST" action="" data-netlify="true" data-netlify-honeypot="bot-field" action="/whitepapers/">
                                        <input type="hidden" name="form-name" value="Blog | Aanmelding nieuwsbrief op de blog"  />
                                        {/*<input type="text" name="lastname" placeholder="Achternaam*" className="margin-xs-bottom" required/>*/}
                                        <input type="email" name="email" placeholder="Emailadres*" className="large combined" required/>
                                        <button type="submit" className="btn primary lg combined">
                                            Aanmelden
                                        </button>
                                      </form>

                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default NewsletterCtaRow
