import React from 'react'
import { Link } from 'gatsby'

const ExplanationCard = ({ title, text }) => (
  <div className="explainer-card">
    <p>
      <strong>{title}</strong>
      {text}
    </p>
  </div>
)

export default ExplanationCard
